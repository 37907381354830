<template>
    <div class="index">
        <div class="addressbook">
            <div class="nav-title">通讯录</div>
            <spider-search @searchTransmit="searchTransmit"/>
            <div class="addressbook-user">
                <div class="commonly-used-list">
                    <p>常用名单</p>
                    <p>{{num}}</p>
                </div>
                <div v-for="(p, i) in addressList" :key="i" class="addressItem">
                    <p class="letterIndex">{{p.initial}}</p>
                    <div
                            v-for="(item, index) in p.result" :key="index"
                            @click="lookUser(index,item)"
                            :class="current == item.memberId ? 'active-background' : 'user-info'"
                    >
                        <img class="user-icon" :src="item.avatar" alt="" v-if="item.avatar"/>
                        <img class="user-icon" v-else src="@/assets/images/touxiang.png" alt=""/>
                        <p class="user_name">{{ item.remark?item.remark:item.nickName }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="address-book-info" v-if="!isShow">
            <div class="info-icon">
                <img :src="usrerMsgObj.avatar" alt="" v-if="usrerMsgObj.avatar"/>
                <img v-else src="@/assets/images/touxiang.png" alt=""/>
                <div class="user-name">
                    <p class="flex-box" v-if="!usrerMsgObj.editStatus">
                        {{usrerMsgObj.remark?usrerMsgObj.remark:usrerMsgObj.nickName}}
                        <i class="el-icon-edit edit-icon" @click="changeEdit"></i>
                    </p>
                    <p class="flex-box" v-else>
                        <el-input v-model="usrerMsgObj.remark" ref="remarkInput" placeholder="请输入备注"
                                  @blur="editRemark"></el-input>
                    </p>
                    <p>{{usrerMsgObj.userName}}</p>
                </div>
            </div>
            <div class="info-detail-box">
                <div class="info-detail">
                    <div>
                        <p>个性签名</p>
                        <p>{{usrerMsgObj.signature}}</p>
                    </div>
                    <div>
                        <p>
                            蜘信昵称/<span>{{usrerMsgObj.nickName}}</span>

                        </p>
                    </div>
                    <div>
                        <p style="letter-spacing: 3.37px">地 区</p>
                        <p v-if="usrerMsgObj.provinceName&&usrerMsgObj.cityName">{{usrerMsgObj.provinceName}}
                            {{usrerMsgObj.cityName}}</p>
                        <p v-else>暂无地区信息</p>
                    </div>
                </div>
                <!-- <div class="info-detail">
                  <div>
                    <p>蜘信昵称</p>
                    <p>{{usrerMsgObj.nickName}}</p>
                  </div>
                </div> -->
            </div>
            <div class="send-message" @click="showChat(usrerMsgObj)">发消息</div>
        </div>
        <spider-window-messagebox ref="messagebox" v-if="isShow"/>
    </div>
</template>

<script>
    import spiderSearch from "../../../components/spider-search/spider-search.vue";
    import spiderWindowMessagebox from "../../../components/spider-window-message/spider-window-messagebox.vue";
    import api from '@/api/api'

    export default {
        components: {spiderSearch, spiderWindowMessagebox},
        data() {
            return {
                current: null,
                addressList: [],
                usrerMsgObj: {},
                num: '',
                saveAddressList: [], //要存下来
                isShow: false, //聊天框的显示和隐藏
                remark: ''
            };
        },
        created() {
            this.getAddressBookList()  //获取通讯录列表
        },
        methods: {
            //x发消息点击展示聊天框
            showChat(item) {
                console.log(item)
                item.memberId = item.id
                item.from = "person"
                this.isShow = true
                //异步一下 防止获取不到ref
                setTimeout(() => {
                    this.$refs.messagebox.init(item)
                }, 200)

            },
            // 查看用户信息
            lookUser(index, item) {
                this.current = item.memberId;
                this.isShow = false
                this.getUserDetail(item.memberId)
            },
            //获取用户详情
            getUserDetail(memberId) {
                api.userMsg.userDetail(memberId).then(res => {
                    this.$set(res.result, 'id', res.result.id.toString())
                    this.usrerMsgObj = res.result
                    this.$set(this.usrerMsgObj, 'editStatus', false)
                    this.remark = this.usrerMsgObj.remark ? this.usrerMsgObj.remark : this.usrerMsgObj.nickName
                    // this.usrerMsgObj.editStatus = false
                })
            },
            //获取通讯录列表
            getAddressBookList(val) {
                //自己构造数组 构造有首字母缩写的的二级数组
                api.addressList.memberList().then(res => {
                    this.dealWithData(res, val)
                }).catch(err => {
                    this.$message({
                        showClose: true,
                        message: err.message,
                        type: 'error'
                    });
                })
            },
            //修改备注
            editRemark() {
                api.member.updateRemarksAndSettings({
                    remark: this.usrerMsgObj.remark
                }, this.usrerMsgObj.id).then(res => {
                    this.usrerMsgObj.editStatus = false
                    this.getUserDetail(this.usrerMsgObj.id)
                    this.getAddressBookList(1)
                })
            },
            //改变备注编辑状态
            changeEdit() {
                this.$set(this.usrerMsgObj, 'editStatus', true)
                this.$nextTick(() => {
                    this.$refs.remarkInput.focus()
                })
            },
            //处理数据 重构数组 增加字母索引 以及 bigNumber 的处理
            dealWithData(res, val) {
                if (res.result) {
                    this.num = res.result.length
                }
                let arr = []
                res.result.forEach(item => {
                    this.$set(item, 'memberId', item.memberId.toString())
                    this.$set(item, 'id', item.id.toString())
                    this.$set(item, 'circleId', item.id.toString())
                    let obj = {}
                    obj.initial = item.initial
                    arr.push(obj)
                })
                //去重
                arr = [...new Set(arr)]
                //过滤
                arr.forEach(item => {
                    item.result = res.result.filter(p => {
                        return p.initial == item.initial
                    })
                })
                //去重
                //每次进来赋值为空
                this.addressList = []
                this.saveAddressList = []
                //数组如果一个个对象没有办法直接去重 需要把对象每个进行字符串化  变成['{xx:xx}','{xx:xx}']
                //构造一个去重数组
                let strArr = []
                arr.forEach(item => {
                    strArr.push(JSON.stringify(item))
                })
                strArr = [...new Set(strArr)]
                //赋值
                strArr.forEach(item => {
                    this.addressList.push(JSON.parse(item))
                    this.saveAddressList.push(JSON.parse(item))
                })
                if (!val) {
                    //获取第一个的展示页面 详情
                    this.getUserDetail(this.addressList[0].result[0].memberId)
                }

            },
            //搜索框输入的值
            searchTransmit(val) {
                this.search(val)
            },
            //查询通讯录
            search(val) {
                api.addressList.getMemberByBasicInfo({scope: 1, condition: val}).then(res => {
                    console.log(res)
                    this.dealWithData(res)
                })
            }
        },
    };
</script>

<style scoped>
    .index {
        display: flex;
        flex: 1;
    }

    .addressbook {
        width: 253px;
        user-select: none;
        cursor: pointer;
        height: 100%;
        background: #ffffff;
    }

    .addressbook .addressbook-user {
        height: calc(100% - 130px);
        flex: 1;
        overflow-x: hidden;
        overflow-y: auto;
        /* padding: 0 12px; */
    }

    .addressbook-user .commonly-used-list {
        height: 38px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0 12px;
    }

    .commonly-used-list p {
        font-size: 14px;
    }

    .commonly-used-list p:nth-child(2) {
        color: #abaeb3;
    }

    .nav-title {
        height: 60px;
        line-height: 60px;
        font-size: 18px;
        letter-spacing: 2px;
        padding: 10px;
        display: flex;
        align-items: center;
        background: #f5f7fa;
        box-sizing: border-box;
        border-bottom: 1px solid #dcdfe6;
    }

    .addressbook-user .user-info {
        height: 56px;
        display: flex;
        align-items: center;
        padding: 0 24px;
    }

    .user-info:hover {
        background: #f5f7fa;
    }

    .active-background {
        height: 56px;
        display: flex;
        align-items: center;
        background: #f5f7fa;
        padding: 0 24px;
    }

    .addressbook-user .user-icon {
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin-right: 8px;
    }

    .addressbook-user .user-info p {
        font-size: 16px;
        color: #313233;
    }

    .address-book-info {
        width: 506px;
        display: flex;
        flex-direction: column;
        padding: 80px;
        background: #ffffff;
        border-left: 1px solid #dcdfe6;
        box-sizing: border-box;
    }

    .address-book-info .info-icon {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #dcdfe6;
    }

    .address-book-info .info-icon img {
        width: 80px;
        height: 80px;
        margin-right: 16px;
        border-radius: 50%;
    }

    .info-icon .user-name {
        height: 80px;
        padding-top: 4px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .info-icon .user-name p:nth-child(1) {
        font-size: 28px;
        color: #313233;
        letter-spacing: 1px;
        margin-bottom: 8px;
    }

    .info-icon .user-name p:nth-child(2) {
        font-size: 14px;
        line-height: 20px;
        color: #7b7d80;
        letter-spacing: 2px;
    }

    .address-book-info .info-detail {
        padding-top: 20px;
        margin-bottom: 100px;
        flex: 1;
        flex-shrink: 0;
    }

    .address-book-info .info-detail div {
        display: flex;
        padding-bottom: 24px;
    }

    .address-book-info .info-detail p {
        font-size: 14px;
    }

    .info-detail div p:nth-child(1) {
        color: #7b7d80;
        margin-right: 20px;
    }

    .send-message {
        width: 200px;
        height: 44px;
        line-height: 44px;
        text-align: center;
        font-size: 18px;
        color: #ffffff;
        letter-spacing: 2px;
        cursor: pointer;
        background: #4db4d4;
        border-radius: 42px;
        margin: 0 auto;
    }

    .addressItem {
        margin-bottom: 12px;
        border-bottom: 1px solid #dcdfe6;
    }

    .letterIndex {
        font-size: 14px;
        font-family: PingFangSC, PingFangSC-Regular;
        font-weight: 400;
        text-align: left;
        color: #7b7d80;
        padding-left: 24px;
        margin-bottom: 12px;
    }

    .info-detail-box {
        display: flex;
        width: 800px;
    }

    .edit-icon {
        margin-left: 5px;
        font-size: 22px;
        color: #DCDFE6;
        display: none;
        cursor: pointer;
    }

    .user-name:hover .edit-icon {
        display: block;
    }

    .flex-box {
        display: flex;
        align-items: center;
    }
</style>
