<template>
  <div class="search">
    <img src="@/assets/images/ic_shousuo1@2x.png" />
    <input type="text" placeholder="搜索(Enter)" v-model="searchText" @input="searchInput" @keyup.enter="transmitData" />
    <div class="search-text"  @click="searchInput">
      搜索
    </div>
  </div>
</template>

<script>
export default {
    data(){
      return{
        searchText:''
      }
    },
    methods:{
      //确认搜索
      transmitData(){
        // console.log(this.searchText)
        this.$emit('searchTransmit',this.searchText)
      },
      //搜索框input事件
      searchInput(){
        this.$emit('searchInput',this.searchText)
      }
    }

};
</script>

<style scoped>
.search {
  width: 100%;
  height: 67px;
  line-height: 67px;
  padding: 0 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid #dcdfe6;
  position: relative;
}
.search img {
  width: 14px;
  height: 14px;
  position: absolute;
  left: 25px;
}
.search input {
  width: 190px;
  height: 40px;
  outline: none;
  border-radius: 20px;
  border: 1px solid #dcdfe6;
  padding-left: 34px;
  padding-right: 15px;
  box-sizing: border-box;
}
.search input::-webkit-input-placeholder {
  color: #abaeb3;
  font-size: 14px;
  letter-spacing: 1px;
}
.search-text{
  margin-left: 10px;
  font-size: 14px;
  flex-shrink: 0;
  color: #999;
}
</style>